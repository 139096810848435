import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "@/components/ui/card"
import { ScrollBar, ScrollArea } from "@/components/ui/scroll-area"
import { Button } from "@/components/ui/button"
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible"
import { match } from "ts-pattern"
import { atom, useAtomValue, useSetAtom } from "jotai"
import { useAtomCallback } from "jotai/utils"
import { ChevronsUpDown, TrashIcon, PlusIcon, Check, ArchiveIcon, ArchiveRestoreIcon, EyeIcon, EyeOffIcon, LockIcon } from "lucide-react"
import { useCallback, useState, useTransition, useMemo, Suspense } from "react"
import type { UIResource } from "shared/data/resource"
import type { ClientToolDef } from "shared/tool"
import { P } from "ts-pattern"
import { j_playgroundId, useResource, useSelectOutputTab, j_selectedOutputTab, j_tools } from "../state"
import { j_baseResources, j_localResources, j_remoteResources } from "../state"
import { Badge } from "@/components/ui/badge"
import { j_runningProcesses, useResourceWSApi, useUpdateResourceArchived } from "../hooks"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"
import { cn } from "@/lib/utils"
import React from "react"
import { ResourceFromToolForm } from "@/components/ResourceGenerateForm"
import { j_currentTeam, j_currentTeamSlug } from "@/state"
import { LabelWithResources } from "../common/LabelWithResources"
import { Skeleton } from "@/components/ui/skeleton"
import { EditableName } from "@/components/EditableName"
import { useUpdateFriendlyName } from "../hooks"
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog"
import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"

const ToolComboBox: React.FC<{
  tools: ClientToolDef[]
  value: string
  onChange: (value: string) => void
}> = ({ tools, value, onChange }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} className="w-[400px] text-sm justify-between">
          {value}
          <ChevronsUpDown className="opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0">
        <Command>
          <CommandInput className="text-sm" placeholder="Search framework..." />
          <CommandList>
            <CommandEmpty>No framework found.</CommandEmpty>
            <CommandGroup>
              {tools.sort().map(tool => (
                <CommandItem
                  key={tool.name}
                  value={tool.name}
                  onSelect={currentValue => {
                    onChange(currentValue === value ? "" : currentValue)
                    setOpen(false)
                  }}
                >
                  <div className="text-sm">
                    {tool.name} {tool.description ? <span className="text-gray-500">- {tool.description}</span> : ""}
                  </div>
                  <Check className={cn("ml-auto", value === tool.name ? "opacity-100" : "opacity-0")} />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

const ResourceStatus: React.FC<{
  className?: string
  status: UIResource["status"]
}> = ({ status, className }) => {
  if (!status) return null
  const badgeColor = match(status)
    .with("draft", () => "bg-white" as const)
    .with("init", () => "bg-orange-400" as const)
    .with("generating", () => "bg-orange-400 animate-pulse" as const)
    .with("error", () => "bg-red-300" as const)
    .with("done", () => "bg-green-300" as const)
    .with("paused", () => "bg-yellow-300" as const)
    .with("suspended", () => "bg-yellow-300" as const)
    .exhaustive()
  return <Badge className={`${className} ${badgeColor} text-black hover:${badgeColor}`}>{status}</Badge>
}

export const ResourcePanelChildResources: React.FC<{ resourceId: string }> = ({ resourceId }) => {
  const allResources = useAtomValue(j_baseResources)
  const childResources = allResources.filter(x =>
    (function isDescendant(child: UIResource, root: string) {
      if (child.parentResource == null) return false
      if (child.parentResource === root) return true
      const parentResource = allResources.find(x => x.id === child.parentResource)
      if (!parentResource) return false
      return false
      //return isDescendant(parentResource, root)
    })(x, resourceId),
  )

  const selectOutputTab = useSelectOutputTab()

  return (
    <>
      <ul className="flex flex-col gap-2">
        {childResources.map(x => {
          return (
            <li key={x.id} className="rounded border-2 p-2 flex flex-col gap-2">
              <div onClick={() => selectOutputTab(x.id)} className="flex flex-row cursor-pointer">
                <div>
                  <div className="text-[0.8rem] text-orange-400">{x.generator?.tool}</div>
                  <div className="text-sm font-bold text-ellipsis overflow-x-hidden text-pretty whitespace-nowrap max-w-[250px]">{x.friendlyName}</div>
                </div>
                <div className="ms-auto">
                  <ResourceStatus className="text-[0.6rem]" status={x.status} />
                </div>
              </div>
              <Collapsible>
                <CollapsibleContent>
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-row">
                      <ul>
                        {Object.entries(x.generator?.args ?? {}).map(([key, value]) => (
                          <li key={key} className="text-[0.7rem] text-ellipsis overflow-hidden max-h-[2rem]">
                            <span className="font-bold">{key}</span>:{" "}
                            {match(value)
                              .with(
                                {
                                  $$resourceId: P.string,
                                },
                                ({ $$resourceId }) => <span className="text-yellow-600 font-black">#{$$resourceId}</span>,
                              )
                              .with(P.string, s => <LabelWithResources value={s} />)
                              .with(P.boolean, x => `${x}`)
                              .with(P.number, x => `${x}`)
                              .otherwise(() => null)}
                          </li>
                        ))}
                      </ul>
                      <div className="ms-auto text-[0.7rem]">#{x.id}</div>
                    </div>
                    <ResourcePanelChildResources resourceId={x.id} />
                  </div>
                </CollapsibleContent>
                <CollapsibleTrigger asChild>
                  <div className="bg-gray-900 p-1">
                    <ChevronsUpDown className="h-4 w-4 mx-auto" />
                  </div>
                </CollapsibleTrigger>
              </Collapsible>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export const ResourcePanel: React.FC<{ 
  resourceId: string;
  onArchive?: (id: string) => void;
  onUnarchive?: (id: string) => void;
  onDelete?: (id: string) => void;
  onDeleteDraft?: (id: string) => void;
}> = ({ resourceId, onArchive, onUnarchive, onDelete, onDeleteDraft }) => {
  const resource = useResource(resourceId, false)
  const availableTools = useAtomValue(j_tools)
  const [toolName, setToolName] = useState<string>(resource.generator?.tool ?? "campaign-manager")
  const tool = availableTools.find(x => x.name === toolName)
  const updateFriendlyName = useUpdateFriendlyName()
  const selectedTab = useAtomValue(j_selectedOutputTab)
  const isSelected = selectedTab === resourceId

  const handleRename = async (newName: string) => {
    await updateFriendlyName({ resourceId, newName })
  }

  const [manualEdit, setManualEdit] = useState(false)
  const isEditing = resource.status === "draft" || manualEdit
  const [, start] = useTransition()

  const abortController = useAtomValue(j_runningProcesses(resourceId))
  const canStop = (abortController !== undefined && resource.status === "generating") || resource.status === "init"

  const wsResourceApi = useResourceWSApi()

  const selectOutputTab = useSelectOutputTab()

  const onSubmit = useCallback(
    async (s: any, regenerate = false) => {
      await wsResourceApi.create({
        resourceId: resourceId,
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        tool: tool!,
        toolArgs: s,
        regenerate: regenerate,
      })
    },
    [resourceId, tool, wsResourceApi],
  )

  const onResume = useCallback(async () => {
    await wsResourceApi.resume(resourceId)
  }, [resourceId, wsResourceApi])

  const team = useAtomValue(j_currentTeam)
  
  const isArchived = resource.archived 
  const isDraft = resource.status === "draft"
  const isFrozen = resource.frozen
  
  return (
    <Card className={`${isArchived ? "border-gray-500" : ""}`}>
      <CardHeader
        onClick={() => {
          if (resource.status !== "draft") {
            selectOutputTab(resourceId)
          }
        }}
        className={`relative ${!isEditing ? "cursor-pointer" : ""}`}
      >
        {!isEditing ? <div className="text-sm text-orange-400">{toolName}</div> : null}
        <CardTitle className="text-lg">
          {isSelected ? (
            <EditableName value={resource.friendlyName ?? "New"} onSave={handleRename} className="text-lg" />
          ) : (
            <span className="px-2 py-1">{resource.friendlyName ?? "New"}</span>
          )}
        </CardTitle>
        <div className="absolute right-4 top-4 flex gap-2">
          {isArchived && <Badge className="bg-gray-500 text-white hover:bg-gray-500">Archived</Badge>}
          {isFrozen && <div title="Frozen, state cannot be changed" className=" text-white flex"><LockIcon className="h-4 w-4 self-center" /></div>}
          <ResourceStatus className="" status={resource.status} />
        </div>
      </CardHeader>
      <CardContent className="flex flex-col gap-2">
        {isEditing ? (
          <>
            <ToolComboBox tools={availableTools} value={toolName} onChange={setToolName} />
            {tool && (
              <ResourceFromToolForm
                team={team?.slug ?? ""}
                key={`${tool.name}-${resourceId}`}
                tool={tool}
                existingResource={resource}
                onSubmit={x => {
                  onSubmit(x, resource.status !== "draft")
                  setManualEdit(false)
                }}
                onEditCancel={() => setManualEdit(false)}
              />
            )}
          </>
        ) : (
          <>
            <Collapsible>
              <CollapsibleTrigger asChild>
                <Button variant="ghost" size="sm" className="flex flex-row gap-2">
                  Args
                  <ChevronsUpDown className="h-4 w-4" />
                </Button>
              </CollapsibleTrigger>
              <CollapsibleContent>
                {Object.entries(resource.generator?.args ?? {}).map(([name, value], i) => (
                  <div className="text-xs p-4" key={name}>
                    <div className="font-black">{name}</div>
                    <div>
                      {match(value)
                        .with({ $$resourceId: P.string }, ({ $$resourceId }) => <span className="text-yellow-600 font-black">#{$$resourceId}</span>)
                        .with(P.string, s => <LabelWithResources value={s} />)
                        .with(P.boolean, x => `${x}`)
                        .with(P.number, x => `${x}`)
                        .otherwise(() => null)}
                    </div>
                  </div>
                ))}
              </CollapsibleContent>
            </Collapsible>
            {resource.output?.childResources?.length ? (
              <Collapsible defaultOpen={false} className="flex flex-col gap-2">
                <CollapsibleTrigger asChild>
                  <Button variant="ghost" size="sm" className="flex justify-start flex-row gap-2">
                    Using
                    <ChevronsUpDown className="h-4 w-4" />
                  </Button>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <ResourcePanelChildResources resourceId={resource.id} />
                </CollapsibleContent>
              </Collapsible>
            ) : null}

            <div className="flex flex-row gap-2">
              {!canStop && !isArchived ? (
                <>
                  <Button size="sm" variant="secondary" onClick={() => setManualEdit(true)}>
                    Edit
                  </Button>

                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={() => {
                      onSubmit(resource.generator?.args, true)
                    }}
                  >
                    Regenerate
                  </Button>
                </>
              ) : null}

              {canStop ? (
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={() => {
                    abortController?.abort()
                  }}
                >
                  Stop
                </Button>
              ) : null}

              {(resource.status === "paused" || (resource.status === "generating" && !canStop)) && !isArchived && !isFrozen ? (
                <Button size="sm" variant="secondary" onClick={onResume}>
                  Resume
                </Button>
              ) : null}
            </div>
          </>
        )}
      </CardContent>
      <CardFooter className="relative">
        <div className="absolute right-4 bottom-2 flex flex-row items-center text-xs gap-1">
          {resource.id}
          {isDraft ? (
            <Button
              size="icon"
              variant="ghost"
              onClick={() => onDeleteDraft?.(resourceId)}
              title="Delete Draft"
              className="hover:text-gray-700 hover:bg-gray-100"
            >
              <TrashIcon className="h-4 w-4" />
            </Button>
          ) : (
            !resource.parentResource && (
              isArchived ? (
                <div className="flex gap-1">
                  <Button
                    size="icon"
                    variant="ghost"
                    onClick={() => onDelete?.(resourceId)}
                    title="Delete"
                    className="text-red-500 hover:text-red-700 hover:bg-red-100"
                  >
                    <TrashIcon className="h-4 w-4" />
                  </Button>
                  <Button
                    size="icon"
                    variant="ghost"
                    onClick={() => onUnarchive?.(resourceId)}
                    title="Unarchive"
                    className="text-green-500 hover:text-green-700 hover:bg-green-100"
                  >
                    <ArchiveRestoreIcon className="h-4 w-4" />
                  </Button>
                </div>
              ) : (
                <Button
                  size="icon"
                  variant="ghost"
                  onClick={() => onArchive?.(resourceId)}
                  title="Archive"
                  className="hover:text-gray-700 hover:bg-gray-100"
                >
                  <ArchiveIcon className="h-4 w-4" />
                </Button>
              )
            )
          )}
        </div>
      </CardFooter>
    </Card>
  )
}

// Create an atom to track whether to show archived resources
const j_showArchivedResources = atom(false)
const j_archivedCount = atom(get => {
  const allResources = get(j_baseResources)
  return allResources.filter(x => !x.parentResource && x.archived).length
})

export const ResourcesPanel = () => {
  const showArchived = useAtomValue(j_showArchivedResources)
  const setShowArchived = useSetAtom(j_showArchivedResources)
  const playground = useAtomValue(j_playgroundId)
  const updateResourceArchived = useUpdateResourceArchived()
  const [, start] = useTransition()
  
  // State for delete confirmation
  const [resourceToDelete, setResourceToDelete] = useState<string | null>(null)
  
  const resources = useAtomValue(
    useMemo(
      () =>
        atom(get => {
          const showArchivedResources = get(j_showArchivedResources)
          return get(j_baseResources)
            .filter(x => !x.parentResource) // Only root resources
            .filter(x => showArchivedResources ? true : !x.archived) // Filter archived resources based on toggle
            .map(x => x.id)
        }),
      [],
    ),
  )
  
  const archivedCount = useAtomValue(j_archivedCount)
  
  const addResource = useAtomCallback(
    useCallback((get, set) => {
      const id = Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)
      set(j_localResources, g => [
        ...g,
        {
          id,
          friendlyName: "new",
          playground: get(j_playgroundId),
          status: "draft",
          trace: [],
        },
      ])
    }, []),
  )
  
  const onDelete = useAtomCallback(
    useCallback(async (get, set, resourceId: string) => {
      set(j_localResources, g => g.filter(x => x.id !== resourceId))
      await fetch(`/api/playgrounds/${playground}/resources/${resourceId}`, {
        method: "DELETE",
      })
      start(() => {
        set(j_remoteResources)
      })
      setResourceToDelete(null)
    }, [playground]),
  )
  
  const onDeleteDraft = useAtomCallback(
    useCallback((get, set, resourceId: string) => {
      // For draft resources, we just remove them from local state without API call
      set(j_localResources, g => g.filter(x => x.id !== resourceId))
    }, []),
  )
  
  const handleArchive = useCallback((resourceId: string) => {
    updateResourceArchived({ 
      resourceId, 
      newArchived: true 
    })
  }, [updateResourceArchived])
  
  const handleUnarchive = useCallback((resourceId: string) => {
    updateResourceArchived({ 
      resourceId, 
      newArchived: false 
    })
  }, [updateResourceArchived])
  
  const handleDeleteRequest = useCallback((resourceId: string) => {
    setResourceToDelete(resourceId)
  }, [])

  return (
    <>
      <div className="flex items-center justify-between p-2 ">
        <div className="flex items-center space-x-2">
          <Switch
            id="show-archived"
            checked={showArchived}
            onCheckedChange={setShowArchived}
          />
          <Label htmlFor="show-archived" className="text-sm">
            Show Archived ({archivedCount})
          </Label>
        </div>
        <Button variant="ghost" size="sm" onClick={addResource}>
          <PlusIcon className="h-4 w-4 mr-1" /> New Resource
        </Button>
      </div>
      <ScrollArea>
        <ScrollBar orientation="vertical" />
        <div className="flex flex-col gap-2 overflow-y-auto p-2">
          {resources.length === 0 ? (
            <div className="text-center text-gray-500 p-4">
              {showArchived 
                ? "No archived resources found" 
                : "No resources found. Create a new resource to get started."}
            </div>
          ) : (
            resources.map(x => (
              <Suspense
                fallback={
                  <div className="w-full">
                    <div className="flex flex-col gap-4">
                      <Skeleton className="h-10 w-full" />
                      <Skeleton className="h-10 w-full" />
                    </div>
                  </div>
                }
                key={x}
              >
                <ResourcePanel 
                  resourceId={x} 
                  onArchive={handleArchive}
                  onUnarchive={handleUnarchive}
                  onDelete={handleDeleteRequest}
                  onDeleteDraft={onDeleteDraft}
                />
              </Suspense>
            ))
          )}
          <Button variant="ghost" className="self-center justify-self-center content-center justify-center" onClick={addResource}>
            <PlusIcon />
          </Button>
        </div>
      </ScrollArea>
      
      <AlertDialog open={resourceToDelete !== null} onOpenChange={(open) => !open && setResourceToDelete(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure you want to delete this resource?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete the resource and all its data.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction 
              className="bg-red-500 hover:bg-red-600"
              onClick={() => resourceToDelete && onDelete(resourceToDelete)}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
