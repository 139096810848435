import { initPromise } from "@/lib/esbuild"
import * as W from "@/wireframe/wireframe"
import React, { type FC, useState, useTransition, useCallback, createElement, useMemo, useEffect, use, useRef, cache } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Textarea } from "./ui/textarea"
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs"
import { transform } from "esbuild-wasm"
import ReactDOMServer from "react-dom/server"
import { withErrorBounderyFallBack } from "@/lib/react-utils"
import { ScrollArea, ScrollBar } from "./ui/scroll-area"
import { memoize } from "lodash"

//await initPromise;

const importsMap = {
  React,
  ...W,
}

const Editor: FC<{ code: string; onChange: (code: string) => void }> = ({ code, onChange }) => {
  return <Textarea onChange={x => onChange(x.target.value)} value={code} style={{ minWidth: "30vw", minHeight: "80vh" }} />
}

const transformCode = memoize(async (code: string) => {
  const compiled = await transform(`return ()=>${code}`, {
    loader: "jsx",
    platform: "browser",
  })
  return compiled.code
})

const Preview = ({ code }: { code: string }) => {
  const compiledCodePromise = transformCode(code)
  const compiledCode = use(compiledCodePromise)
  const { Comp, html } = useMemo(() => {
    const c = new Function(...Object.keys(importsMap), compiledCode)(...Object.values(importsMap))
    const str = ReactDOMServer.renderToString(createElement(c))
    const node = document.createElement("div")
    node.innerHTML = str
    /*
          document.body.appendChild(node)
          const svg = elementToSVG(node, {
              
          })
          document.body.removeChild(node)
          */
    //const svgString = new XMLSerializer().serializeToString(svg)
    return {
      Comp: c,
      html: str,
      //  svg: svgString
    }
  }, [compiledCode])

  return (
    <div className="wire-html bg-white text-black wireframe">
      <Comp />
    </div>
  )
}

const Preview2 = ({ code }: { code: string }) => {
  const [error, setError] = useState<Error | null>(null)
  const Comp = useRef<React.ComponentType | null>(null)
  const [codeInUse, setCodeInUse] = useState(code)

  useEffect(() => {
    ;(async function () {
      try {
        const compiled = await transform(`return ()=>${code}`, {
          loader: "jsx",
          platform: "browser",
        })
        const c = new Function(...Object.keys(importsMap), compiled.code)(...Object.values(importsMap))
        const str = ReactDOMServer.renderToString(createElement(c))
        Comp.current = c
        setCodeInUse(code)
      } catch (e) {
        setError(e)
      }
    })()
  }, [code])
  return <div className="wire-html bg-white text-black">{Comp.current ? <Comp.current /> : null}</div>
}

const PreviewWithFallback = withErrorBounderyFallBack(Preview)

export const WireframeEditor: FC<{
  code: string
  onCodeChange: (code: string) => void
}> = ({ code, onCodeChange }) => {
  //const [code, setCode] = useState(initialCode);
  const [previewCode, setPreviewCode] = useState(code)
  const [isUpdating, startTransition] = useTransition()
  const resetError = React.useRef(() => {})
  useEffect(() => {
    resetError.current()
    resetError.current = () => {}
    startTransition(() => setPreviewCode(code))
  }, [code])

  return (
    <Tabs defaultValue="preview" className="w-full flex-col flex items-start overflow-hidden">
      <TabsList>
        <TabsTrigger value="code">Code</TabsTrigger>
        <TabsTrigger value="preview">Wireframe</TabsTrigger>
      </TabsList>
      <TabsContent value="code">
        <Editor code={code} onChange={onCodeChange} />
      </TabsContent>
      <TabsContent value="preview" asChild>
        <ScrollArea className="w-full">
          <ScrollBar orientation="vertical" />
          <React.Suspense>
            <ErrorBoundary
              onError={e => {}}
              fallbackRender={e => {
                console.error(e)
                resetError.current = e.resetErrorBoundary
                return <div>error {`${e.error}`}</div>
              }}
            >
              <PreviewWithFallback code={previewCode} />
            </ErrorBoundary>
          </React.Suspense>
        </ScrollArea>
      </TabsContent>
    </Tabs>
  )
}

/*
<div style={{ flexDirection: "column", display: "flex" }}>
          <Editor code={code} onChange={onCodeChange} />
        </div>
        <div id="preview" style={{ flexGrow: 1 }}>
          <React.Suspense>
            <ErrorBoundary
              onError={(e)=> {}}
              fallbackRender={(e) => {
                console.error(e);
                resetError.current = e.resetErrorBoundary;
                return <div>error {`${e.error}`}</div>;
              }}
            >
              <PreviewWithFallback code={previewCode} />
            </ErrorBoundary>
          </React.Suspense>
        </div>
*/
