import { getResourceOutput } from "@/lib/resource-utils"
import type { UIResource } from "shared/data/resource"
import { getSuspendTraceData } from  "@/lib/suspend-utils"
import { ScrollBar } from "@/components/ui/scroll-area"
import type { ErrorBoundary } from "react-error-boundary"
import { StepsPanel } from "./stepspanel"
import { ResizablePanel } from "@/components/ui/resizable"
import { ScrollArea} from "@/components/ui/scroll-area"
import { useState } from "react"
import { useResourceWSApi } from "../hooks"
import { MarkdownWithOriginal } from "@/components/Markdown"
import { visit } from 'unist-util-visit';


const autocloseDirectives = (input:string) => {
    const lines = input.split('\n');
    const output = [];
    let openDirective = null;
  
    for (const line of lines) {
      const directiveMatch = line.match(/^:::(\w+)/);
      const closeDirective = line.match(/^:::$/);
      if (directiveMatch) {
        openDirective = directiveMatch[1];
      }
      if (closeDirective) {
        openDirective = null;
      }
      output.push(line);
    }
  
    if (openDirective) {
      output.push(':::'); // close last open
    }
  
    return output.join('\n');
  };


const remarkSpeechBubble = () => {
  return (tree: any) => {
    visit(tree, 'containerDirective', (node) => {
        if (node.name !== 'chat') return;
      
        const attrs = node.attributes || {};
        const data = node.data || (node.data = {});
      
        const role = attrs.role || 'user'; // fallback if needed
      
        data.hName = 'div';
        data.hProperties = {
          className: ['chat-bubble', `chat-${role}`],
          ...attrs, // optional: include any other data-* attributes
        };
      });
      
  };
}

export const ThreadPanel = ({ resource }: { resource: UIResource }) => {
  let output = getResourceOutput(resource)
  output = autocloseDirectives(output ?? "")
  const trace = getSuspendTraceData(resource)
  const resourceApi = useResourceWSApi()
  const [message, setMessage] = useState("")
  
  
  return <ResizablePanel className="rounded-xl p-2 border-accent border-2 flex flex-col" defaultSize={20}>
    <div className="flex flex-col flex-grow overflow-hidden">
        <MarkdownWithOriginal remarkPlugins={[remarkSpeechBubble]} className="flex-grow">
            {output ?? ""}
        </MarkdownWithOriginal>
    </div>
    
    <div className="mt-auto pt-2 border-t border-accent">
      <div className="flex items-center">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="flex-grow rounded-md border border-accent bg-background px-3 py-2 text-sm"
          placeholder="Type your message..."
        />
        <button 
          type="submit"
          className="ml-2 rounded-md bg-primary px-3 py-2 text-sm text-primary-foreground hover:bg-primary/90"
          onClick={() => {
            if (!trace) {
              throw new Error("No trace")
            }
            // Handle message sending
            if (message.trim()) {
              resourceApi.release({
                rootResourceId: trace.rootContext,
                triggeringResourceId: trace.contextName,
              }, { args: [trace.args], result: {message} })
              console.log("Sending message:", message);
              setMessage("");
            }
          }}
        >
          Send
        </button>
      </div>
    </div>
  </ResizablePanel>
}