import { createRoute, Link, Outlet, type AnyRoute } from "@tanstack/react-router"
import { adminUsersRoute } from "./users"
import { adminToolsRoute } from "./tools"
import { useState } from "react"
import { Button } from "@/components/ui/button"
import { ChevronLeftIcon, ChevronRightIcon, UsersIcon, SatelliteIcon } from "lucide-react"
import { layoutRoute } from "@/layout"

export const adminRoute = createRoute({
  getParentRoute: () => layoutRoute,
  beforeLoad: async () => {},
  path: "/admin",
  component: function admin() {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
    const menuItems = [
      { name: "Users", icon: UsersIcon, path: "users" },
      { name: "Tools", icon: SatelliteIcon, path: "tools" }
    ]
    return (
      <div className="flex h-screen ">
        {/* Sidebar */}
        <aside className={`bg-gray-900 text-white transition-all duration-300 ease-in-out ${sidebarCollapsed ? "w-24" : "w-64"}`}>
          <div className="p-4 flex justify-between items-center">
            {!sidebarCollapsed && <h2 className="text-xl font-semibold">Admin Panel</h2>}
            <Button variant="ghost" size="icon" onClick={() => setSidebarCollapsed(!sidebarCollapsed)} className="text-white hover:bg-gray-700">
              {sidebarCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </Button>
          </div>
          <nav className="mt-4 px-4 gap-4 flex-col flex">
            {menuItems.map(item => (
              <Link key={item.name} from={adminRoute.fullPath} to={`./${item.path}`}>
                <Button key={item.name} variant="secondary" className="w-full text-left p-4 flex items-center">
                  <item.icon className="h-5 w-5 mr-2" />
                  {!sidebarCollapsed && <span>{item.name}</span>}
                </Button>
              </Link>
            ))}
          </nav>
        </aside>

        {/* Main content */}
        <main className="flex-1 p-8 overflow-auto">
          <div className="max-w-4xl mx-auto">
            <Outlet />
          </div>
        </main>
      </div>
    )
  },
})

adminRoute.addChildren([
  adminUsersRoute(adminRoute),
  adminToolsRoute(adminRoute)
])
