import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs"
import { useMemo, useRef, useCallback, useEffect, useState } from "react"
import { useResource } from "../state"
import { getResourceOutput } from "@/lib/resource-utils"
import { Button } from "@/components/ui/button"
import { FileText, Smartphone } from "lucide-react"

const useIframeTitle = (iframeRef: React.RefObject<HTMLIFrameElement>) => {
  const [pageTitle, setPageTitle] = useState("Untitled Page")

  useEffect(() => {
    const updateTitle = () => {
      const iframeDocument = iframeRef.current?.contentDocument
      if (iframeDocument) {
        const title = iframeDocument.title || "Untitled Page"
        if (title !== pageTitle) {
          setPageTitle(title)
        }
      }
    }
    const interval = setInterval(updateTitle, 500)

    return () => {
      clearInterval(interval)
    }
  }, [iframeRef.current, pageTitle])

  return pageTitle
}

export const IFrameTitleBar = ({
  ref,
  isMobileView,
  onMobileViewChange,
}: { ref: React.RefObject<HTMLIFrameElement>; isMobileView: boolean; onMobileViewChange: (isMobileView: boolean) => void }) => {
  const pageTitle = useIframeTitle(ref)
  return (
    <div className="bg-background border-b flex items-center px-4 py-1">
      <div className="flex-1 flex items-center gap-2">
        <FileText className="h-4 w-4 text-muted-foreground" />
        <span className="text-sm">{pageTitle}</span>
      </div>
      <Button variant="ghost" size="icon" onClick={() => onMobileViewChange(!isMobileView)} className={isMobileView ? "text-primary" : "text-muted-foreground"}>
        <Smartphone className="h-4 w-4" />
      </Button>
    </div>
  )
}

export const WebsitePanel: React.FC<{ resourceRef: string }> = ({ resourceRef }) => {
  const source = useResource(resourceRef)
  const generatedCode = useMemo(() => getResourceOutput(source)?.replace(/^\`\`\`html(.*)\`\`\`$/s, "$1" ?? "") ?? "", [source])
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const docIsOpen = useRef(false)
  const currentDocPosition = useRef(0)
  const previous = useRef<string | null>(generatedCode)
  const [isMobileView, setIsMobileView] = useState(false)
  useEffect(() => {
    if (previous.current === generatedCode) {
      return
    }
    if (previous.current && generatedCode && !generatedCode.startsWith(previous.current)) {
      iframeRef.current?.contentDocument?.close()
      iframeRef.current?.contentDocument?.open()
      iframeRef.current?.contentDocument?.write(generatedCode)
      docIsOpen.current = true
      currentDocPosition.current = generatedCode.length
    }
    previous.current = generatedCode
  }, [generatedCode])

  const syncContent = useCallback(() => {
    if (!iframeRef.current || !generatedCode) {
      return
    }
    if (currentDocPosition.current < generatedCode.length) {
      const doc = iframeRef.current.contentDocument
      if (!docIsOpen.current) {
        doc?.open()
        docIsOpen.current = true
      }
      doc?.write(generatedCode.slice(currentDocPosition.current))
      currentDocPosition.current = generatedCode.length
    }
    if (source.status === "done" && docIsOpen.current && currentDocPosition.current === generatedCode?.length) {
      docIsOpen.current = false
      iframeRef.current?.contentDocument?.close()
    }
  }, [iframeRef.current, generatedCode, source.status])
  useEffect(() => {
    syncContent()
  }, [syncContent])

  return (
    <Tabs className="flex flex-col h-full overflow-hidden" defaultValue="preview">
      <TabsList className="justify-start">
        <TabsTrigger value="preview">Preview</TabsTrigger>
        <TabsTrigger value="source">Source</TabsTrigger>
      </TabsList>
      <TabsContent asChild value="preview">
        <div className={`flex flex-col flex-1 w-full h-full`}>
          <IFrameTitleBar ref={iframeRef} isMobileView={isMobileView} onMobileViewChange={setIsMobileView} />
          <iframe
            title="website"
            ref={e => {
              if (e) {
                iframeRef.current = e
              }
              if (e && !e["synced"]) {
                currentDocPosition.current = 0
                e["synced"] = true
                syncContent()
              }
            }}
            className={`flex bg-white transition-all duration-300 ${isMobileView ? "w-[390px] mx-auto" : "w-full"} flex-1 h-full`}
          />
        </div>
      </TabsContent>
      <TabsContent value="source" asChild className="flex flex-1 overflow-auto">
        <pre className="text-xs">{generatedCode}</pre>
      </TabsContent>
    </Tabs>
  )
}
