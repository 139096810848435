import { Input } from "@/components/ui/input"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { type AnyRoute, createRoute, useLoaderData, useRouter } from "@tanstack/react-router"
import { ofetch } from "ofetch"
import { useState } from "react"
import { SearchIcon, XIcon } from "lucide-react"
import type { Team } from "../../../../shared/data/team"

type Tool = {
  name: string
  description: string
  annotations: {
    visibility?: "private" | "public"
    [key: string]: any
  }
  args: string
  outputType: string
}


export const adminToolsRoute = <TParentRoute extends AnyRoute = AnyRoute>(parent: TParentRoute) => {
  const r = createRoute({
    getParentRoute: () => parent,
    path: "/tools",
    loader: async () => {
      const response = await ofetch<{ tools: Tool[] }>("/api/admin/tools")
      return response
    },
    component: function ToolsManagement() {
      const data = r.useLoaderData()
      const tools = data?.tools || []
      const router = useRouter()
      const [searchTerm, setSearchTerm] = useState("")
      const [teamSlugs, setTeamSlugs] = useState<Record<string, string>>({})
      const [toolTeams, setToolTeams] = useState<Record<string, Team[]>>({})
      const [loadingTeams, setLoadingTeams] = useState<Record<string, boolean>>({})
      const [deletingTeam, setDeletingTeam] = useState<Record<string, boolean>>({})

      // Filter tools based on search term
      const filteredTools = tools.filter(
        (tool: Tool) => 
          tool.name?.toLowerCase().includes(searchTerm.toLowerCase()) || 
          tool.description?.toLowerCase().includes(searchTerm.toLowerCase())
      )

      const isToolPrivate = (tool: Tool) => {
        return tool.annotations?.visibility === "private"
      }

      const fetchTeamsForTool = async (toolName: string) => {
        try {
          setLoadingTeams(prev => ({ ...prev, [toolName]: true }))
          const response = await ofetch<{ teams: Team[] }>(`/api/admin/tools/${toolName}/visibility-rule`)
          setToolTeams(prev => ({ ...prev, [toolName]: response.teams || [] }))
        } catch (error) {
          console.error(`Failed to load teams for tool ${toolName}:`, error)
        } finally {
          setLoadingTeams(prev => ({ ...prev, [toolName]: false }))
        }
      }

      const addTeamAccess = async (toolName: string) => {
        if (!teamSlugs[toolName]) return
        
        try {
          setLoadingTeams(prev => ({ ...prev, [toolName]: true }))
          await ofetch(`/api/admin/tools/${toolName}/visibility-rule`, {
            method: "POST",
            body: {
                teamSlug: teamSlugs[toolName],
            }
          })
          
          await fetchTeamsForTool(toolName)
          setTeamSlugs(prev => ({ ...prev, [toolName]: "" }))
        } catch (error) {
          console.error("Failed to add team access:", error)
          alert("Failed to add team access. Make sure the team slug is correct.")
        } finally {
          setLoadingTeams(prev => ({ ...prev, [toolName]: false }))
        }
      }

      const deleteTeamAccess = async (toolName: string, teamSlug: string) => {
        try {
          const teamKey = `${toolName}-${teamSlug}`
          setDeletingTeam(prev => ({ ...prev, [teamKey]: true }))
          
          await ofetch(`/api/admin/tools/${toolName}/visibility-rule/${teamSlug}`, {
            method: "DELETE"
          })
          
          await fetchTeamsForTool(toolName)
        } catch (error) {
          console.error("Failed to delete team access:", error)
          alert("Failed to delete team access.")
        } finally {
          const teamKey = `${toolName}-${teamSlug}`
          setDeletingTeam(prev => ({ ...prev, [teamKey]: false }))
        }
      }

      const toggleTeamsSection = async (toolName: string) => {
        if (!toolTeams[toolName] && isToolPrivate(tools.find((t: Tool) => t.name === toolName)!)) {
          await fetchTeamsForTool(toolName)
        }
      }

      return (
        <div>
          <h1 className="text-3xl font-semibold mb-6">Tools Management</h1>
          
          <div className="relative mb-6">
            <SearchIcon className="absolute left-3 top-3 h-4 w-4 text-gray-500" />
            <Input
              placeholder="Search tools..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10"
            />
          </div>

          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Tool</TableHead>
                <TableHead>Description</TableHead>
                <TableHead>Visibility</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredTools.map((tool: Tool) => (
                <TableRow key={tool.name} className="group">
                  <TableCell className="font-medium">{tool.name}</TableCell>
                  <TableCell>{tool.description}</TableCell>
                  <TableCell>
                    <Badge variant={isToolPrivate(tool) ? "destructive" : "default"}>
                      {isToolPrivate(tool) ? "Private" : "Public"}
                    </Badge>
                  </TableCell>
                  <TableCell>
                    {isToolPrivate(tool) && (
                      <Button 
                        variant="outline" 
                        size="sm"
                        onClick={() => toggleTeamsSection(tool.name)}
                      >
                        Manage Access
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {filteredTools.map((tool: Tool) => {
                if (!isToolPrivate(tool) || !toolTeams[tool.name]) return null
                
                return (
                  <TableRow key={`${tool.name}-teams`}>
                    <TableCell colSpan={4} className="p-4">
                      <div className="pl-6">
                        <h3 className="text-sm font-medium mb-2">Teams with access:</h3>
                        
                        {loadingTeams[tool.name] ? (
                          <div className="py-2 flex items-center space-x-2">
                            <div className="h-4 w-4 rounded-full border-2 border-blue-500 border-t-transparent animate-spin" />
                            <span className="text-sm text-gray-500">Loading teams...</span>
                          </div>
                        ) : (
                          <>
                            <div className="flex flex-wrap gap-2 mb-4">
                              {toolTeams[tool.name]?.length === 0 ? (
                                <span className="text-sm text-gray-500">No teams have access</span>
                              ) : (
                                toolTeams[tool.name]?.map(team => {
                                  const teamKey = `${tool.name}-${team.slug}`
                                  const isDeleting = deletingTeam[teamKey]
                                  
                                  return (
                                    <div key={team.id} className="flex items-center">
                                      <Badge variant="outline" className="bg-background flex items-center gap-2">
                                        <span>{team.name || team.slug}</span>
                                        <button
                                          type="button"
                                          className="hover:bg-gray-200 rounded-full p-0.5"
                                          onClick={() => deleteTeamAccess(tool.name, team.slug)}
                                          disabled={isDeleting}
                                          title="Remove team access"
                                        >
                                          {isDeleting ? (
                                            <div className="h-3 w-3 rounded-full border border-red-400 border-t-transparent animate-spin" />
                                          ) : (
                                            <XIcon className="h-3 w-3 text-gray-600" />
                                          )}
                                        </button>
                                      </Badge>
                                    </div>
                                  )
                                })
                              )}
                            </div>
                            
                            <div className="flex items-center gap-2">
                              <Input
                                placeholder="Team slug"
                                value={teamSlugs[tool.name] || ""}
                                onChange={(e) => setTeamSlugs(prev => ({ ...prev, [tool.name]: e.target.value }))}
                                className="w-64"
                              />
                              <Button 
                                variant="secondary" 
                                size="sm"
                                disabled={!teamSlugs[tool.name]}
                                onClick={() => addTeamAccess(tool.name)}
                              >
                                Add Team Access
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      )
    },
  })
  return r
} 