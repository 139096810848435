import { Suspense, useCallback, useEffect, useMemo, useRef, type FC } from "react"
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable"
import { Badge } from "@/components/ui/badge"
import { ResourcesPanel } from "./resources"
import { OutputsPanel } from "./outputs/outputs"
import { j_baseResources, j_resourceFocusMode, j_selectedOutputTab, j_playground } from "./state"
import { useAtom, useSetAtom, useAtomValue } from "jotai"
import type { ImperativePanelHandle } from "react-resizable-panels"
import { ChevronLeftIcon, ChevronsRightIcon, Settings } from "lucide-react"
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area"
import { useAtomCallback } from "jotai/utils"
import { Button } from "@/components/ui/button"
import { EditableName } from "@/components/EditableName"
import { useUpdatePlayground } from "./hooks"

export const PlaygroundHome: FC = () => {
  const [collapsed, setCollapsed] = useAtom(j_resourceFocusMode)
  const sidebarRef = useRef<ImperativePanelHandle>(null)
  const setSelectedOutputTab = useSetAtom(j_selectedOutputTab)
  const playground = useAtomValue(j_playground)
  const selectDefaultTab = useAtomCallback(
    useCallback((get, set) => {
      const outputTab = get(j_selectedOutputTab)
      if (outputTab) return
      const resources = get(j_baseResources).filter(x => !x.parentResource)
      if (resources.length === 0) return
      set(j_selectedOutputTab, resources[0].id)
    }, []),
  )

  const updatePlayground = useUpdatePlayground()
  const handleUpdatePlaygroundName = useCallback(async (newName: string) => {
    await updatePlayground({
      name: newName,
    })
  }, [updatePlayground])

  useEffect(() => {
    if (collapsed) {
      selectDefaultTab()
    }
  }, [collapsed])

  return (
    <ResizablePanelGroup direction="horizontal" className="h-full w-full">
      <ResizablePanel
        ref={sidebarRef}
        className="p-1 px-2 border-accent flex flex-col relative"
        onCollapse={() => setCollapsed(true)}
        onExpand={() => setCollapsed(false)}
        collapsible={true}
        collapsedSize={2}
        minSize={10}
        defaultSize={collapsed ? 2 : 20}
      >
        {collapsed ? (
          <>
            <ChevronsRightIcon
              className="w-6 h-6 my-auto mx-auto cursor-pointer"
              onClick={() => {
                sidebarRef.current?.expand(20)
              }}
            />
          </>
        ) : null}
        <div className={`${collapsed ? "hidden" : "flex flex-col overflow-hidden"}`}>
          <div className="flex flex-row justify-between items-center my-1 border-b border-accent py-1">
            <div className="flex flex-col p-2 grow">
              <div className="text-muted-foreground text-xs">Playground</div>
              <EditableName 
                value={playground.name}
                onSave={handleUpdatePlaygroundName}
                className="text-lg font-semibold"
              />
            </div>
            <div className="flex justify-end">
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8"
                onClick={() => {
                  setSelectedOutputTab("settings")
                }}
              >
                <Settings className="h-4 w-4" />
              </Button>
            </div>
          </div>

          <ScrollArea className="flex-1">
            <ScrollBar orientation="vertical" />
            <Suspense fallback={<div className="flex-1 flex items-center justify-center">Loading...</div>}>
              <ResourcesPanel />
            </Suspense>
          </ScrollArea>
        </div>
      </ResizablePanel>
      <ResizableHandle withHandle />
      <ResizablePanel className="p-1 border-accent overflow-y-auto flex flex-col relative">
        <Suspense fallback={<div className="flex-1 flex items-center justify-center">Loading...</div>}>
          <OutputsPanel />
        </Suspense>
      </ResizablePanel>
    </ResizablePanelGroup>
  )
}
