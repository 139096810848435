import { useEffect, useState } from "react"
import { Search } from "lucide-react"
import { useNavigate } from "@tanstack/react-router"
import { CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"
import { useAtomValue } from "jotai"
import { j_currentTeamSlug } from "@/state"
import { cn } from "@/lib/utils"
import { j_playgroundId } from "@/pages/playground/state"

interface SearchResult {
  id: string
  name: string
  playground: string
  generator_tool?: string
  preview_description?: string
}

export function SearchCommand() {
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState("")
  const [results, setResults] = useState<SearchResult[]>([])
  const navigate = useNavigate()
  const teamSlug = useAtomValue(j_currentTeamSlug)
  const playgroundId = useAtomValue(j_playgroundId)

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setOpen(open => !open)
      }
    }
    document.addEventListener("keydown", down)
    return () => document.removeEventListener("keydown", down)
  }, [])

  const searchResources = async (search: string, signal: AbortSignal) => {
    if (!teamSlug) return
    try {
      const response = await fetch(`/api/search?team=${teamSlug}&query=${encodeURIComponent(search)}`, { signal })
      if (!response.ok) throw new Error("Search failed")
      const data = await response.json()
      setResults(data)
    } catch (error) {
      // ignore abort errors
      if (error instanceof Error && error.name === "AbortError") {
        return
      }
      setResults([])
    }
  }

  useEffect(() => {
    let abortController = new AbortController()
    const delayDebounceFn = setTimeout(() => {
      searchResources(query, abortController.signal)
    }, 200)

    return () => {
      clearTimeout(delayDebounceFn)
      abortController.abort()
    }
  }, [query, teamSlug])

  // if playgroundId is provided, it should be sorted and in different group

  return (
    <CommandDialog
      commandProps={{ shouldFilter: false }}
      modal={true}
      open={open}
      onOpenChange={setOpen}
      contentClassName={cn(
        "fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] max-w-lg w-full bg-background",
        "data-[state=open]:animate-in data-[state=closed]:animate-out",
        "data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
        "data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95",
        "data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%]",
        "data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]",
      )}
    >
      <CommandInput placeholder="Search resources..." value={query} onValueChange={setQuery} />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup heading="Resources">
          {results.map(result => (
            <CommandItem
              key={result.id}
              onSelect={() => {
                navigate({ to: `/p/${result.playground}#outputTab="${result.id}"` })
                setOpen(false)
              }}
            >
              <Search className="mr-2 h-4 w-4" />
              <div className="flex flex-col">
                <span>{result.name}</span>
                {result.preview_description && <span className="text-xs text-muted-foreground">{result.preview_description.substring(0, 100)}...</span>}
              </div>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </CommandDialog>
  )
}
