import { WireframeEditor } from "@/components/WireframeEditor"
import { useMemo, useState, useEffect } from "react"
import { useResource } from "../state"
import { getResourceOutput } from "@/lib/resource-utils"

/**
 * Closes open JSX tags, attributes (quotes), curly braces {}, parentheses (),
 * and square brackets [] in a potentially incomplete JSX string.
 * It handles tags or attributes cut off mid-stream.
 * Uses a state machine approach scanning character by character.
 * CORRECTED for ReferenceError: prevChar is not defined and duplicate '>' chars.
 *
 * @param jsxString The potentially incomplete JSX string.
 * @returns The JSX string with appropriate closing elements appended.
 */
function closeOpenJSX(jsxString: string): string {
  const stack: (string | '{' | '(' | '[')[] = [];
  let i = 0;
  let currentOutput = "";

  // States for parsing:
  let inTag = false;
  let tagName: string | null = null;
  let isPotentiallyClosing = false;
  let lookingForTagName = false;

  // States for context *outside* tags
  let inText_SingleQuote = false;
  let inText_DoubleQuote = false;
  let inText_TemplateLiteral = false;
  let inText_LineComment = false;
  let inText_BlockComment = false;

  // States for context *inside* tags (attributes)
  let inAttribute_SingleQuote = false;
  let inAttribute_DoubleQuote = false;
  let inAttribute_JsxExpression = 0;

  while (i < jsxString.length) {
      const char = jsxString[i];
      // Define prevChar INSIDE the loop scope where it's used
      const prevChar = i > 0 ? jsxString[i - 1] : null;
      const nextChar = i < jsxString.length - 1 ? jsxString[i + 1] : null;
      let advanceIndex = true; // Assume we increment i unless told otherwise

      if (inTag) {
          // --- Processing Character WHILE INSIDE A TAG ---
          currentOutput += char;

          if (lookingForTagName) {
              if (/[a-zA-Z0-9_.-]/.test(char)) {
                  tagName = (tagName || '') + char;
              } else {
                  lookingForTagName = false;
                  currentOutput = currentOutput.slice(0, -1); // Remove char temporarily
                  advanceIndex = false; // Re-process this char
              }
          } else if (inAttribute_SingleQuote) {
              // Use loop-scoped prevChar here
              if (char === "'" && prevChar !== '\\') inAttribute_SingleQuote = false;
          } else if (inAttribute_DoubleQuote) {
               // Use loop-scoped prevChar here
              if (char === '"' && prevChar !== '\\') inAttribute_DoubleQuote = false;
          } else if (inAttribute_JsxExpression > 0) {
              if (char === '{') inAttribute_JsxExpression++;
              else if (char === '}') inAttribute_JsxExpression--;
          } else {
              // Not inside quotes or {} within the tag attributes
              if (char === "'") inAttribute_SingleQuote = true;
              else if (char === '"') inAttribute_DoubleQuote = true;
              else if (char === '{') inAttribute_JsxExpression++;
              else if (char === '/') {
                  if (nextChar === '>') {
                      currentOutput += '>';
                      i++; // Consume '>'
                      inTag = false;
                      tagName = null;
                      isPotentiallyClosing = false;
                  }
              } else if (char === '>') {
                  inTag = false;
                  if (isPotentiallyClosing) {
                      if (tagName && stack.length > 0 && stack[stack.length - 1] === tagName) {
                          stack.pop();
                      }
                  } else if (tagName) {
                      stack.push(tagName);
                  }
                  tagName = null;
                  isPotentiallyClosing = false;
              }
          }
      } else {
          // --- Processing Character WHILE OUTSIDE A TAG ---
           if (inText_BlockComment) {
              currentOutput += char;
               // Use loop-scoped prevChar here
              if (char === '/' && prevChar === '*') inText_BlockComment = false;
          } else if (inText_LineComment) {
              currentOutput += char;
              if (char === '\n') inText_LineComment = false;
          } else if (inText_SingleQuote) {
              currentOutput += char;
               // Use loop-scoped prevChar here
              if (char === "'" && prevChar !== '\\') inText_SingleQuote = false;
          } else if (inText_DoubleQuote) {
              currentOutput += char;
               // Use loop-scoped prevChar here
              if (char === '"' && prevChar !== '\\') inText_DoubleQuote = false;
          } else if (inText_TemplateLiteral) {
              currentOutput += char;
               // Use loop-scoped prevChar here
              if (char === '`' && prevChar !== '\\') inText_TemplateLiteral = false;
          } else {
              // Not currently inside comments or strings
              if (char === '<') {
                  inTag = true;
                  lookingForTagName = true;
                  tagName = null;
                  isPotentiallyClosing = (nextChar === '/');
                  if (isPotentiallyClosing) {
                       currentOutput += '</';
                       i++; // Consume '/'
                  } else {
                       currentOutput += '<';
                  }
              } else {
                  currentOutput += char;
                  if (char === '/' && nextChar === '/') { inText_LineComment = true; i++; currentOutput += '/'; }
                  else if (char === '/' && nextChar === '*') { inText_BlockComment = true; i++; currentOutput += '*'; }
                  else if (char === "'") inText_SingleQuote = true;
                  else if (char === '"') inText_DoubleQuote = true;
                  else if (char === '`') inText_TemplateLiteral = true;
                  else if (char === '{' || char === '(' || char === '[') {
                      stack.push(char);
                  } else if (char === '}') {
                      if (stack.length > 0 && stack[stack.length - 1] === '{') stack.pop();
                  } else if (char === ')') {
                      if (stack.length > 0 && stack[stack.length - 1] === '(') stack.pop();
                  } else if (char === ']') {
                      if (stack.length > 0 && stack[stack.length - 1] === '[') stack.pop();
                  }
              }
          }
      }

      if (advanceIndex) {
          i++;
      }
  } // End while loop

  // --- Handle EOF State ---
  let closingString = "";
  // Get the actual last character processed FROM THE ORIGINAL STRING, if any
  // 'i' is jsxString.length at this point, so last char index is i - 1
  const lastProcessedChar = i > 0 ? jsxString[i - 1] : null;

  // 1. Close unclosed attribute quotes/expressions if EOF was inside a tag
  if (inTag) {
      if (inAttribute_SingleQuote) closingString += "'";
      if (inAttribute_DoubleQuote) closingString += '"';
      if (inAttribute_JsxExpression > 0) closingString += '}'.repeat(inAttribute_JsxExpression);
  }

  // 2. Close the tag itself if EOF was inside
  if (inTag) {
      // Use the correctly scoped lastProcessedChar
      if (lastProcessedChar === '/') {
           closingString += ">"; // Complete the self-closing tag like <Tag />
      } else {
          closingString += ">"; // Assume `<Tag ...` needs `>`
          // If it was an opening tag being processed, push it onto stack *before* closing
          if (!isPotentiallyClosing && tagName) {
               stack.push(tagName);
          }
      }
       // Note: If it was a closing tag like `</Foo` cut off, we don't try to fix the stack here.
  }

  // 3. Close remaining items on the main stack (tags and delimiters)
  while (stack.length > 0) {
      const openItem = stack.pop();
      switch (openItem) {
          case '{': closingString += '}'; break;
          case '(': closingString += ')'; break;
          case '[': closingString += ']'; break;
          default:
              if (typeof openItem === 'string') {
                  closingString += `</${openItem}>`;
              }
              break;
      }
  }

  return currentOutput + closingString;
}




export const WireframePanel: React.FC<{ resourceRef: string }> = ({ resourceRef }) => {
  const source = useResource(resourceRef)
  const generatedCode = useMemo(() => getResourceOutput(source), [source])
  const [code, setCode] = useState(generatedCode ?? "")
  const [editMode, setEditMode] = useState(false)
  useEffect(() => {
    if (source.status === "generating") {
      setEditMode(false)
    }
  }, [source.status])
  const isGenerating = source.status === "generating"
  const codeToUse = editMode ? code : ((isGenerating && generatedCode ? closeOpenJSX(generatedCode) : generatedCode) ?? "")

  return (
    <WireframeEditor
      code={codeToUse}
      onCodeChange={data => {
        if (source.status !== "generating") {
          setEditMode(true)
          setCode(data)
        }
      }}
    />
  )
}
