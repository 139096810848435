import { Suspense, type JSX } from "react"
import { useResource, useSelectOutputTab } from "../state"
import { parseMacros } from "../../../../shared/llm/macros"

const ResourceLabel = ({ resourceId }: { resourceId: string, resourceDescription?: string }) => {
  const selectTab = useSelectOutputTab()
  const resource = useResource(resourceId)
  return (
    <span className="text-orange-400 underline cursor-pointer" onClick={() => selectTab(resourceId)}>
      #{resource.friendlyName} (#{resource.id})
    </span>
  )
}

export const LabelWithResources: React.FC<{
  value: string
  className?: string
}> = ({ value, className }) => {
  //[@@resource id='7GHuX/FeNGs' description='Brand Guidelines: Faye Insights' /]
  const parts = [] as React.ReactNode[]
  let lastIndex = 0
  parseMacros(value, (macro, offset, length) => {
    if (offset !== lastIndex) {
      parts.push(value.substring(lastIndex, offset))
    }
    if (macro.name !== "resource"){
      return ""; 
    }
    parts.push(
      <Suspense fallback={<span className="text-orange-400">#{macro.attributes.id}</span>}>
    <ResourceLabel resourceId={macro.attributes.id} key={macro.attributes.id} />
      </Suspense>
    )
    lastIndex = offset + length
    return ""
  })
  parts.push(value.substring(lastIndex))
  return <span className={className}>{parts}</span>
}
