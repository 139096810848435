import { j_currentTeamSlug } from "@/state"
import { useAtomValue, atom } from "jotai"
import { atomFamily } from "jotai/utils"
import { ofetch } from "ofetch"
import { prop, sortBy } from "remeda"

export interface ResourceSearchResult {
  id: string
  name: string
  playground: string
  generator_tool: string
  generated_at: number
  archived: boolean
  preview_description: string
}

const pageSizeAtom = atom(10)

const resourcesAtom = atomFamily((tools: string) =>
  atom(async get => {
    const currentTeamSlug = get(j_currentTeamSlug)
    if (!currentTeamSlug) return []
    const page = document.location.search.split("page=")[1]
    const data = await ofetch<ResourceSearchResult[]>("/api/search", {
      params: {
        team: currentTeamSlug,
        generatorTool: tools,
        pageSize: 10,
        page: page ? Number.parseInt(page) : 1,
      },
    })

    return data
  }),
)

export function useFilteredResources(generatorTools: string | string[]) {
  const tools = Array.isArray(generatorTools) ? generatorTools.join(",") : generatorTools
  let data = useAtomValue(resourcesAtom(tools))
  data = sortBy(data, [prop("archived"), "asc"], [prop("generated_at"), "desc"])
  return { data }
}
