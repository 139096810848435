import { createRoute, redirect, useNavigate, type AnyRoute } from "@tanstack/react-router"
import { ofetch } from "ofetch"
import type { ClientToolDef } from "shared/tool"
import { z } from "zod"
import jsonSchemaToZod from "json-schema-to-zod"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { customAlphabet, urlAlphabet } from "nanoid"
import { ResourceFromToolForm } from "../../components/ResourceGenerateForm"
import { layoutRoute } from "@/layout"
import { j_currentTeam, j_rootStore } from "@/state"
import { useAtomValue } from "jotai"
import { teamRoute } from "@/app"

const resourceId = customAlphabet(urlAlphabet, 5)

export const newPlaygroundRoute = createRoute({
  getParentRoute: () => teamRoute,
  path: "/new-playground",
  validateSearch: search => {
    return {
      tool: (search["tool"] as string) ?? undefined,
    }
  },
  beforeLoad: async ({ context, search }) => {
    if (!search.tool) {
      const team = await j_rootStore.get(j_currentTeam)
      const playgroundRes = await fetch("/api/playgrounds", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          name: "main",
          team: team?.id,
        }),
      }) //.then(x=>x.json())
      if (!playgroundRes.ok) {
        throw new Error("Failed to create playground")
      }
      const { id } = await playgroundRes.json()
      return redirect({
        to: `/p/${id}`,
        throw: true,
      })
    }
  },
  loader: async context => {
    const tools = await ofetch<{ tools: ClientToolDef[] }>("/api/tools").then(x =>
      x.tools.map(t => ({
        ...t,
        args: new Function("z", `return (${jsonSchemaToZod(JSON.parse(t.args as unknown as string), { module: "none" })})`)(z),
      })),
    )
    return tools.find(t => t.name === context.location.search.tool) as ClientToolDef
  },
  component: () => {
    const tool = newPlaygroundRoute.useLoaderData()
    return <NewPlaygroundFromTool tool={tool} />
  },
})

const NewPlaygroundFromTool: React.FC<{ tool: ClientToolDef }> = ({ tool }) => {
  const navigate = useNavigate()
  const team = useAtomValue(j_currentTeam)
  return (
    <div className="flex flex-col w-full my-20">
      <Card className="mx-auto my-auto w-1/2">
        <CardHeader>
          <CardTitle>new {tool.name}</CardTitle>
        </CardHeader>
        <CardContent>
          <ResourceFromToolForm
            team={team?.slug ?? ""}
            tool={tool}
            onSubmit={async s => {
              //Create new playground
              const { id: playgroundId } = await ofetch("/api/playgrounds", {
                method: "POST",
                headers: {
                  "content-type": "application/json",
                },
                body: JSON.stringify({
                  name: "main",
                  team: team?.id,
                }),
              })
              // Create new resource
              const _resourceId = resourceId()
              await fetch(`/api/playgrounds/${playgroundId}/resources?no-generate`, {
                method: "POST",
                headers: {
                  "content-type": "application/json",
                },
                body: JSON.stringify({
                  id: _resourceId,
                  generator: {
                    tool: tool.name,
                    args: s,
                  },
                  friendlyName: "new",
                }),
              })

              // Redirect to new playground
              navigate({
                to: `/p/${playgroundId}?connect=${_resourceId}`,
                replace: true,
              })
            }}
          />
        </CardContent>
      </Card>
    </div>
  )
}
