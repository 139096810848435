import { Textarea } from "@/components/ui/textarea"
import { Button } from "@/components/ui/button"
import { atom, useAtomValue, useSetAtom } from "jotai"
import { atomWithRefresh } from "jotai/utils"
import { XCircleIcon } from "lucide-react"
import { ofetch } from "ofetch"
import { useState } from "react"
import { j_playgroundId } from "../state"

const j_settings = atomWithRefresh(async get => {
  const playground = get(j_playgroundId)
  const settings = await ofetch<Record<string, unknown>>(`/api/playgrounds/${playground}/settings`)
  return settings
})

const j_update_settings = atom(undefined, async (get, set, value: Record<string, unknown>) => {
  const playground = get(j_playgroundId)
  await ofetch(`/api/playgrounds/${playground}/settings`, {
    method: "PUT",
    body: value,
  })
  set(j_settings)
})

export const SettingsPanel = () => {
  const settings = useAtomValue(j_settings)
  const updateSettings = useSetAtom(j_update_settings)
  const [jsonValue, setJsonValue] = useState<string>(JSON.stringify(settings, null, 4))
  const [jsonError, setJsonError] = useState<string | null>(null)

  const handleJsonChange = async (value: string) => {
    setJsonValue(value)
    try {
      JSON.parse(value)
      setJsonError(null)
    } catch (error) {
      setJsonError((error as Error).message)
    }
  }

  return (
    <div className="flex flex-col gap-6 w-full h-full p-6">
      <div className="space-y-4">
        <h3 className="text-lg font-medium">Settings</h3>
        <p className="text-sm text-muted-foreground">Configure your playground settings in JSON format</p>
      </div>

      <div className="space-y-4">
        <Textarea
          value={jsonValue}
          onChange={e => handleJsonChange(e.target.value)}
          className="font-mono text-sm min-h-[300px] resize-none border-2"
          placeholder="Enter your JSON data here..."
        />

        {jsonError && (
          <div className="rounded-lg border-2 border-destructive/50 bg-destructive/50 p-4">
            <div className="flex gap-2 items-center">
              <XCircleIcon className="h-5 w-5" />
              <p className="font-medium">JSON Validation Error</p>
            </div>
            <p className="mt-2 text-sm">{jsonError}</p>
          </div>
        )}
      </div>

      <div className="flex justify-end">
        <Button
          disabled={!!jsonError}
          onClick={async () => {
            if (jsonError) return
            await updateSettings(JSON.parse(jsonValue))
          }}
          className="px-8"
        >
          Save Changes
        </Button>
      </div>
    </div>
  )
}
