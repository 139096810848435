import { atom, useAtomValue, useSetAtom, type Getter } from "jotai"
import { atomFamily, atomWithDefault, atomWithRefresh, selectAtom, splitAtom, unwrap, useAtomCallback } from "jotai/utils"
import type { UIResource } from "../../../shared/data/resource"
import { urlAlphabet, customAlphabet } from "nanoid"
import { useMemo } from "react"
import type { Register } from "@tanstack/react-router"
import { atomWithHash } from "jotai-location"
import { ofetch } from "ofetch"
import jsonSchemaToZod from "json-schema-to-zod"
import type { ClientToolDef } from "shared/tool"
import { z } from "zod"
import type { Playground } from "shared/data/playground"

const resourceId = customAlphabet(urlAlphabet, 5)

export const j_localResources = atomWithDefault<UIResource[]>(get =>
  get(j_remoteResources_sync).length > 0
    ? []
    : [
        {
          id: resourceId(),
          playground: get(j_playgroundId),
          trace: [],
          friendlyName: "new",
          status: "draft",
        },
      ],
)

const atomWithAsyncRefresh = <T>(fn: (get: Getter) => Promise<T>) => {
  const invalidate = atom({})
  const baseAtom = atom(async get => {
    get(invalidate)
    return await fn(get)
  })
  return atom(
    async get => {
      return await get(baseAtom)
    },
    (get, set) => {
      set(invalidate, {})
      return get(baseAtom)
    },
  )
}
export const j_remoteResources = atomWithAsyncRefresh(async get => {
  const pid = get(j_playgroundId)
  const { resources }: { resources: UIResource[] } = await fetch(`/api/playgrounds/${pid}/resources`).then(x => x.json())
  return resources
})

export const j_loadedResources = atom<UIResource[]>([])

const j_loadResource = atomFamily((resourceId: string) => {
  return atom(
    async (get, set) => {
      const playgroundId = get(j_playgroundId)
      const res = await ofetch<UIResource>(`/api/playgrounds/${playgroundId}/resources/${resourceId}`)
      set.setSelf(res)
      return res
    },
    (get, set, value: UIResource) => {
      set(j_loadedResources, g => [...g.filter(x => x.id !== resourceId), value])
      return value
    },
  )
})

export const j_remoteResources_sync = unwrap(j_remoteResources, prev => prev ?? [])

export const j_baseResources = atom<UIResource[]>(get => {
  const remoteResources = get(j_remoteResources_sync)
  const local = get(j_localResources)
  const loaded = get(j_loadedResources)
  //const external = loaded.filter(x => x.playground !== get(j_playgroundId))
  const remote = remoteResources.filter(x => !loaded.find(y => y.id === x.id)).concat(loaded)
  return remote
    .filter(x => !local.find(y => y.id === x.id))
    .concat(local)
    .sort((a, b) => (a.generatedAt ?? Infinity) - (b.generatedAt ?? 0))
})

j_baseResources.debugLabel = "j_baseResources"

export const j_selectedOutputTab = atomWithHash<string | null>("outputTab", null)

export const j_externalResource = atomFamily((id: string) => {
  const [playgroundId, resourceId] = id.includes("/") ? id.split("/") : [get(j_playgroundId), id]
  const data = atomWithRefresh(async get => {
    const res = await fetch(`/api/playgrounds/${playgroundId}/resources/${resourceId}`)
    return Object.assign(await res.json(), { id: id } /* Hack */)
  })
  const syncData = unwrap(
    data,
    prev =>
      prev ?? {
        id: id,
        playground: playgroundId,
        trace: [],
        friendlyName: "External",
        status: "init",
      },
  )
  return syncData
})

export const useResource = (id: string, includeTrace = true) => {
  return useAtomValue(includeTrace ? j_resourceById(id) : j_resourceByIdTraceless(id))
}

export const j_resourceByIdTraceless = atomFamily((id: string) => {
  return atom(async get => {
    const r = get(selectAtom(j_baseResources, t => t.find(x => x.id === id)))
    if (!r) {
      return await get(j_resourceById(id))
    }
    return {
      ...r,
      trace: [],
    }
  })
})

export const j_resourceById = atomFamily((id: string) => {
  const a = atom<Promise<UIResource>, [(s: UIResource) => UIResource], UIResource>(
    async get => {
      if (id.includes("/")) {
        return get(j_externalResource(id))
      }
      const r = get(selectAtom(j_baseResources, t => t.find(x => x.id === id)))
      if (!r || ("__partial" in r)) {
        const p = await get(j_loadResource(id))
        return p
      }
      return r
    },
    (get, set, update: (s: UIResource) => UIResource) => {
      const all = get(j_baseResources)
      const local = get(j_localResources)
      const current = all.find(x => x.id === id)
      if (!current) {
        throw new Error(`Resource ${id} not found`)
      }
      const isLocal = local.find(x => x.id === id)
      const updated = isLocal ? local.map(x => (x.id === id ? update(x) : x)) : [...local, update(current)]
      set(j_localResources, updated)
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      return updated.find(x => x.id === id)!
    },
  )
  a.debugLabel = `j_resourceById(${id})`
  return a
})

export const j_openedOutputs = atomWithDefault<string[]>(get => {
  const selected = get(j_selectedOutputTab)
  return get(j_baseResources)
    .filter(x => x.status !== "draft")
    .filter(x => x.parentResource === null || x.id === selected)
    .map(x => x.id)
})

export const j_resourceFocusMode = atomWithDefault(get => {
  const resources = get(j_remoteResources_sync)
  return resources.filter(x => !x.parentResource).length === 1
})

const tabStack = atom<string[]>([])
const tabStackPop = atom(null, (get, set) => {
  const tabs = get(tabStack)
  const [last] = tabs.slice(-1)
  const all = tabs.slice(0, -1)
  set(tabStack, all)
  return last
})

export const j_setOutputTab = atom(null, (get, set, resourceId: string) => {
  set(j_selectedOutputTab, resourceId)
  set(tabStack, g => [...g, resourceId])
  set(j_openedOutputs, g => [...new Set([...g, resourceId])])
})

export const useSelectOutputTab = () => {
  return useSetAtom(j_setOutputTab)
}

export const useCloseOutputTab = () => {
  return useMemo(
    useAtomCallback((get, set) => (resourceId: string) => {
      const currentTab = get(j_selectedOutputTab)
      set(j_openedOutputs, g => g.filter(x => x !== resourceId))
      let target = null as string | null
      const openedTabs = get(j_openedOutputs)
      if (currentTab === resourceId) {
        while (true) {
          target = set(tabStackPop)
          if (target === resourceId) {
            continue
          }
          if (!target) {
            break
          }
          if (!openedTabs.includes(target)) {
            continue
          }
          break
        }
        set(j_selectedOutputTab, target)
      }
    }),
    [],
  )
}

export const j_availableOutputs = atom(get => {
  const openedOutputs = get(j_openedOutputs)
  return get(j_baseResources)
    .filter(x => x.status !== "draft")
    .filter(x => openedOutputs.includes(x.id))
})

export const j_allSuspendedResources = selectAtom(
  j_baseResources,
  x => x.filter(x => x.status === "suspended"),
  (x, y) => x.length === y.length && x.every((v, i) => v === y[i]),
)

export const j_suspendedResources = atom(get => {
  const allSuspendedResources = get(j_allSuspendedResources)
  function suspendedChildren(resource: (typeof allSuspendedResources)[number]): (typeof allSuspendedResources)[number][] {
    if (resource.status !== "suspended") {
      return []
    }
    if (!resource.output?.childResources.length) {
      return [resource]
    }
    return resource.output.childResources.flatMap(x => {
      const r = allSuspendedResources.find(y => y.id === x)
      if (!r) {
        return []
      }
      return suspendedChildren(r)
    })
  }
  return [...new Set(allSuspendedResources.flatMap(x => suspendedChildren(x)))]
})

export const j_playgroundId = atom()



export const j_playgroundLocalState = atom<Playground | null>(null)

export const j_playground = atom(async get => {
  if (get(j_playgroundLocalState)) {
    return get(j_playgroundLocalState)
  }
  const id = get(j_playgroundId)
  const playground = await fetch(`/api/playgrounds/${id}`).then(x => x.json())
  return playground
})

export const j_router = atom(null as Register["router"] | null)


export const j_tools = atom(
  async get =>
    await fetch("/api/tools")
      .then(x => x.json())
      .then(x => {
        const tools = x.tools as ClientToolDef & { args: string }[]
        return tools.map(x => {
          return {
            ...x,
            args: new Function("z", `return (${jsonSchemaToZod(JSON.parse(x.args), { module: "none" })})`)(z),
          } as ClientToolDef
        })
      }),
)