import { Suspense } from "react"
import { useFilteredResources } from "./hooks"
import { ResourceCard } from "./ResourceCard"
import { Skeleton } from "@/components/ui/skeleton"

const BrandsList = () => {
  const { data: resources } = useFilteredResources(["brand-researcher", "brand-researcher-v2", "create-brand-document"])
  return (
    <div className="flex flex-col gap-4">
      {resources.map(resource => (
        <ResourceCard key={resource.id} resource={resource} />
      ))}
    </div>
  )
}

export function BrandsView() {
  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-3xl font-bold mb-2 text-gray-800 dark:text-gray-100">Brands</h1>
        <p className="text-muted-foreground mb-6">Brand research results</p>
      </div>
      <Suspense
        fallback={
          <div className="flex flex-col gap-4">
            {Array.from({ length: 3 }).map((_, i) => (
              <Skeleton key={i} className="h-32 w-full rounded-lg" />
            ))}
          </div>
        }
      >
        <BrandsList />
      </Suspense>
    </div>
  )
}
