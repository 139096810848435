import type { ToolTrace } from "./resource-stream"

export type Resource<T = string> = {
  id: string
  playground: string
  friendlyName: string
  parentResource?: string
  generatedAt?: number
  revision: number
  trace: ToolTrace[]
  archived: boolean
  frozen?: boolean
  generator: {
    tool: string
    args: Record<string, unknown>
    state?: unknown
    outputType: string
  }
  status: "draft" | "init" | "done" | "generating" | "error" | "paused" | "suspended"
  output?: {
    childResources: string[]
    data?: T
  }
}

type CompletedResource<T = string> = Resource<T> & {
  status: "done" | "error"
  output: {
    childResources: string[]
    data: T
  }
}

type GeneratingResource<T = string> = Resource<T> & {
  status: "generating"
  output: {
    childResources: string[]
    data: T
  }
}

type DraftResource<T = string> = Omit<Resource<T>, "generator" | "output"> & {
  generator?: Resource<T>["generator"]
  status: "draft"
}

type InitResource<T = string> = Resource<T> & {
  status: "init"
  output: undefined
}

type PausedResource<T = string> = Resource<T> & {
  status: "paused"
  output?: {
    childResources: string[]
    data?: T
  }
}

type SuspendedResource<T = string> = Resource<T> & {
  status: "suspended"
  output?: {
    childResources: string[]
    data?: T
  }
}

export type UIResource = DraftResource | InitResource | GeneratingResource | CompletedResource | PausedResource | SuspendedResource

export const genResourceId = () => Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)
