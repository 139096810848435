import { ScrollArea } from "@radix-ui/react-scroll-area"
import { Scroll } from "lucide-react"
import ReactMardown from "react-markdown"
import { ScrollBar } from "./ui/scroll-area"
import remarkGfm from "remark-gfm"
import remarkDirective from "remark-directive"
import rehypeColorChips from "rehype-color-chips"
import { remarkWrapColors } from "../../shared/utils/markdown/color-plugin"

export const MarkdownWithOriginal: React.FC<{
  children: string
  className: string
  remarkPlugins?: any[]
  rehypePlugins?: any[]
}> = ({ children, className, remarkPlugins, rehypePlugins }) => {
  /*const html = useMemo(()=>micromark(children, {
        allowDangerousHtml: true,

    }), [children]);*/
  return (
    <ScrollArea className="overflow-auto">
      <ScrollBar orientation="vertical" />
      <ReactMardown remarkPlugins={[remarkDirective, remarkGfm, remarkWrapColors, ...remarkPlugins ?? []]} rehypePlugins={[rehypeColorChips, ...rehypePlugins ?? []]} className={`prose prose-slate dark:prose-invert min-w-full ${className}`}>
        {children}
      </ReactMardown>
    </ScrollArea>
  )
}
