import { ResourceFlow } from "@/components/Resource-Flow"
import { Button } from "@/components/ui/button"
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs"
import { match, P } from "ts-pattern"
import { useAtomValue, useAtom, atom } from "jotai"
import { ClipboardCopy, ExternalLink, XCircleIcon } from "lucide-react"
import { Suspense, useCallback, useMemo, useRef } from "react"
import { j_selectedOutputTab, useSelectOutputTab, useCloseOutputTab, j_availableOutputs, j_resourceById, j_playgroundId, useResource, j_localResources, j_tools } from "../state"
import { WireframePanel } from "./wireframe"
import { MarkdownPanel } from "./markdown"
import { SuspendResourcePanel, SuspendWidget } from "./suspend"
import { WebsitePanel } from "./website"
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable"
import { Badge } from "@/components/ui/badge"
import { genResourceId, type UIResource } from "../../../../shared/data/resource"
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area"
import type { ImperativePanelHandle } from "react-resizable-panels"
import { ImagePanel } from "./image"
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card"
import { useCopyToClipboard } from "react-use"
import { j_runningProcesses, useGetRootParentResource, useResourceWSApi } from "../hooks"
import { ErrorBoundary } from "react-error-boundary"
import { StepsPanel } from "./stepspanel"
import { InteractiveRemixPanel } from "./interactive-remix"
import { Link } from "@tanstack/react-router"
import { SettingsPanel } from "./settings"
import { LabelWithResources } from "../common/LabelWithResources"
import { unwrap, useAtomCallback } from "jotai/utils"
import { EditableName } from "@/components/EditableName"
import { useUpdateFriendlyName } from "../hooks"
import { mapValues } from "remeda"
import { ThreadPanel } from "./thread"

export const OutputsPanel = () => {
  const selectOutputTab = useSelectOutputTab()
  const closeOutputTab = useCloseOutputTab()

  const [tab, setTab] = useAtom(j_selectedOutputTab)
  const ref = useRef(null)

  const visibleResources = useAtomValue(
    useMemo(
      () =>
        unwrap(
          atom(async get => {
            const _tab = get(j_selectedOutputTab)
            const available = get(j_availableOutputs)
            return [
              ...available,
              ...(_tab && _tab !== "settings" && _tab !== "flow" && available.every(x => x.id !== _tab)
                ? [
                    ...(await get(j_resourceById(_tab)).then(
                      x => [x],
                      ex => [],
                    )),
                  ]
                : []),
            ]
          }),
          prev => prev ?? [],
        ),
      [],
    ),
  )
  const selectedTab = tab && (tab === "settings" || visibleResources.some(x => x.id === tab)) ? tab : "flow"

  if (visibleResources.length === 0 && selectedTab !== "settings") {
    return null
  }
  return (
    <Tabs
      ref={ref}
      className="flex flex-col overflow-hidden flex-1"
      value={selectedTab}
      onValueChange={x => {
        setTab(x)
      }}
    >
      <TabsList className="border-b-2 border-b-accent w-full bg-transparent justify-start overflow-x-auto min-h-9 gap-2 py-0 p-0">
        {selectedTab === "settings" ? (
          <div className="flex flex-row items-center border-t-2 border-l-2 border-r-2 px-1 border-accent">
            <TabsTrigger className="text-sm py-1 px-1 my-0 border-none" value="settings">
              Settings
            </TabsTrigger>
          </div>
        ) : null}
        <div className="flex flex-row items-center border-t-2 border-l-2 border-r-2 px-1 border-accent">
          <TabsTrigger className="text-sm py-1 px-1 my-0 border-none" value="flow">
            Flow
          </TabsTrigger>
        </div>
        {visibleResources.map(x => (
          <div key={x.id} className="flex flex-row items-center border-t-2 border-l-2 border-r-2 px-1 border-accent">
            <TabsTrigger className="text-sm py-1 px-1 my-0 border-none" key={x.id} value={x.id}>
              {x.friendlyName ?? x.id} {x.id.includes("/") ? "(External)" : ""}
            </TabsTrigger>
            <Button
              size="icon"
              variant="ghost"
              className="h-4 w-4"
              onClick={e => {
                closeOutputTab(x.id)
                e.stopPropagation()
              }}
            >
              <XCircleIcon />
            </Button>
          </div>
        ))}
      </TabsList>
      <div className="p-2 overflow-y-auto flex flex-col flex-1">
        {selectedTab === "settings" ? (
          <TabsContent className="p-4 h-full" key="settings" value="settings">
            <SettingsPanel />
          </TabsContent>
        ) : null}
        <ErrorBoundary
          key="flow"
          fallbackRender={e => {
            return <div>Failed to rendered flow tree</div>
          }}
        >
          <TabsContent key="flow" asChild value="flow">
            <ResourceFlow onResourceClicked={id => selectOutputTab(id)} />
          </TabsContent>
        </ErrorBoundary>

        {visibleResources.map(x => {
          return (
            <ErrorBoundary
              key={x.id}
              fallbackRender={e => {
                return (
                  <div>
                    Failed to rendered resource #{x.id} {`${e.error}`}
                  </div>
                )
              }}
            >
              <TabsContent asChild key={x.id} value={x.id}>
                <Suspense fallback={<div>Loading...</div>}>
                  <ResourceTab resource={x} isActive={x.id === tab} />
                </Suspense>
              </TabsContent>
            </ErrorBoundary>
          )
        })}
      </div>
    </Tabs>
  )
}

const ResourceArg = ({ id }: { id: string }) => {
  const resource = useResource(id)
  const selectTab = useSelectOutputTab()
  // biome-ignore lint/a11y/useValidAnchor: <explanation>
  return (
    <a
      className="text-orange-400  underline cursor-pointer"
      onClick={() => {
        selectTab(id)
      }}
    >
      {resource.friendlyName ? `#${resource.friendlyName} (#${resource.id})` : `#${resource.id}`}
    </a>
  )
}

const PreviewArgs = ({ args }: { args: Record<string, unknown> }) => {
  return (
    <div className="grid grid-cols-[1fr,2fr] gap-4">
      {Object.entries(args).map(([k, v]) => (
        <>
          <div key={k}>{k}</div>
          <div key={`${k}-value`}>
            {match(v)
              .with({ $$resourceId: P.string }, x => <ResourceArg id={x.$$resourceId} />)
              .with(P.string, x => <LabelWithResources value={x} />)
              .with(P.number, x => x)
              .with(P.boolean, x => `${x}`)
              .otherwise(() => JSON.stringify(v))}
          </div>
        </>
      ))}
    </div>
  )
}

const ResourceTab = ({
  resource: lazyResource,
  isActive,
}: {
  resource: UIResource
  isActive: boolean
}) => {
  const resource = useResource(lazyResource.id)
  const isExternalResource = resource.id.includes("/")
  const tracePanel = useRef<ImperativePanelHandle>(null)
  const playground = useAtomValue(j_playgroundId)
  const artifact = match(resource.generator)
    .with({ tool: "interactive-remix" }, () => <InteractiveRemixPanel resource={resource} />)
    .with({ outputType: "wireframe" }, () => <WireframePanel resourceRef={resource.id} />)
    .with({ outputType: "document" }, () => <MarkdownPanel resourceRef={resource.id} />)
    .with({ outputType: "website" }, () => <WebsitePanel resourceRef={resource.id} />)
    .with({ outputType: "image" }, () => <ImagePanel resourceRef={resource.id} />)
    .with({ outputType: "suspender" }, () => <SuspendResourcePanel resource={resource} />)
    .with({ outputType: "thread" }, () => <ThreadPanel resource={resource} />)
    .otherwise(() => <MarkdownPanel resourceRef={resource.id} />)

  const badgeColor = match(resource.status)
    .with("draft", () => "bg-white" as const)
    .with("init", () => "bg-orange-400" as const)
    .with("generating", () => "bg-orange-400 animate-pulse" as const)
    .with("error", () => "bg-red-300" as const)
    .with("done", () => "bg-green-300" as const)
    .with("paused", () => "bg-yellow-300" as const)
    .with("suspended", () => "bg-yellow-300" as const)
    .exhaustive()

  const status = resource.status
  const outputType = resource.generator?.outputType

  const resourceRawUrl = useMemo(() => {
    const queryParams = new URLSearchParams()
    if (status !== "done" && status !== "error") {
      queryParams.set("in-progress", "true")
    }
    if (outputType === "document") {
      queryParams.set("render-md", "true")
    }
    if (isExternalResource) {
      const [xPlayground, xResource] = resource.id.split("/")
      return new URL(`/api/playgrounds/${xPlayground}/resources/${xResource}/raw${queryParams.toString() ? `?${queryParams.toString()}` : ""}`, document.location.href).href
    }
    return new URL(`/api/playgrounds/${playground}/resources/${resource.id}/raw${queryParams.toString() ? `?${queryParams.toString()}` : ""}`, document.location.href).href
  }, [resource.id, playground, status, outputType])
  const [clipboardState, copyToClipboard] = useCopyToClipboard()
  const isRoot = !resource.parentResource
  const isArchived = resource.archived
  const isFrozen = resource.frozen
  const rootParent = isRoot || isExternalResource ? resource : useGetRootParentResource(resource.id)!
  const resourceApi = useResourceWSApi()
  const abortController = useAtomValue(j_runningProcesses(rootParent.id))
  const updateFriendlyName = useUpdateFriendlyName()

  const tools = useAtomValue(j_tools)
  const isToolAvailable = resource.generator?.tool && tools.some(x => x.name === resource.generator?.tool)

  const handleRename = async (newName: string) => {
    await updateFriendlyName({ resourceId: resource.id, newName })
  }

  const handleDuplicate = useAtomCallback(
    useCallback((get, set) => {
      const id = genResourceId()
      set(j_localResources, g => [
        ...g,
        {
          id,
          generator: {
            tool: resource.generator!.tool,
            outputType: resource.generator!.outputType,
            args: mapValues(resource.generator?.args ?? {}, (v)=> {
              if (v && typeof v === "object" && "$$resourceId" in v) {
                return `[@@resource id='${v.$$resourceId}' description='${v.$$resourceId}' /]`
              }
              return v
            }),
          },
          friendlyName: `copy of ${resource.friendlyName ?? resource.id}`,
          playground: get(j_playgroundId),
          status: "draft",
          trace: [],
        },
      ])
    }, []),
  )
  return (
    <div className="flex flex-col flex-1 relative overflow-hidden">
      <div className="flex items-center gap-3 p-2">
        <span className="text-xs text-orange-400">{resource.generator?.tool}</span>

        <div className="flex items-center gap-2">
          {isRoot ? (
            <EditableName value={resource.friendlyName ?? resource.id} onSave={handleRename} className="text-md" />
          ) : (
            <h2 className="text-md px-2 py-1">{resource.friendlyName ?? resource.id}</h2>
          )}
          <Badge className={`text-xs leading-3 ${badgeColor} text-black hover:${badgeColor}`}>{resource.status}</Badge>
        </div>

        <div className="flex items-center gap-2 ml-auto">
          <span className="text-sm text-gray-400">#{resource.id}</span>

          {!isExternalResource ? (
            <HoverCard>
              <HoverCardTrigger asChild>
                <Button size="sm" variant="link">
                  Args
                </Button>
              </HoverCardTrigger>
              <HoverCardContent className="w-fit max-w-[60vw]">
                <PreviewArgs args={resource.generator?.args ?? {}} />
              </HoverCardContent>
            </HoverCard>
          ) : (
            <Link target="_blank" to={`/p/${resource.id.split("/")[0]}#outputTab="${resource.id.split("/")[1]}"`}>
              <Button variant="link" size="sm">
                External
              </Button>
            </Link>
          )}

          {isRoot &&
            !isExternalResource &&
            !isArchived &&
            !isFrozen &&
            match({
              status: resource.status,
              ab: abortController,
            })
              .with({ ab: P.nonNullable }, ({ ab }) => (
                <Button size="sm" variant="outline" onClick={() => ab.abort()}>
                  Stop
                </Button>
              ))
              .with(
                {
                  ab: P.nullish,
                  status: P.when(x => x !== "error" && x !== "draft" && x !== "done"),
                },
                () => (
                  <Button size="sm" variant="outline" onClick={() => resourceApi.resume(resource.id)}>
                    Resume
                  </Button>
                ),
              )
              .otherwise(() => (
                <Button
                  size="sm"
                  variant="outline"
                  onClick={() => {
                    resourceApi.create({
                      regenerate: true,
                      resourceId: resource.id,
                      tool: {
                        name: resource.generator!.tool,
                        outputType: resource.generator!.outputType,
                      },
                      args: resource.generator?.args,
                    })
                  }}
                >
                  Regenerate
                </Button>
              ))}

          {isToolAvailable && (
            <Button size="sm" variant="outline" onClick={handleDuplicate}>
              Duplicate
            </Button>
          )}

          <Button size="sm" variant="outline" onClick={() => window.open(resourceRawUrl, "_blank")}>
            <ExternalLink className="mr-2 h-4 w-4" /> Open
          </Button>

          <Button size="sm" variant="outline" onClick={() => copyToClipboard(resourceRawUrl)}>
            <ClipboardCopy className="mr-2 h-4 w-4" /> {clipboardState.value ? "Copied!" : "Copy URL"}
          </Button>

          {rootParent?.id && rootParent.id !== resource.id && !rootParent.archived && !rootParent.frozen && (
            <Button size="sm" variant="outline" onClick={() => resourceApi.regenerateFromCheckpoint(rootParent?.id, resource.id)}>
              Regenerate from here
            </Button>
          )}
        </div>
      </div>
      {isExternalResource ? (
        <div className="p-1 m-2 overflow-auto">{artifact}</div>
      ) : (
        <ResizablePanelGroup direction="horizontal" className="flex flex-1 relative overflow-auto gap-1">
          <ResizablePanel ref={tracePanel} className="rounded-xl p-2 border-accent border-2 flex flex-col gap-4" defaultSize={20}>
            <ScrollArea>
              <ScrollBar orientation="vertical" />
              <ErrorBoundary
                fallbackRender={e => {
                  return <div>Failed to render steps: {`${e.error}`}</div>
                }}
              >
                <StepsPanel resource={resource} />
              </ErrorBoundary>
            </ScrollArea>
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel className="rounded-xl p-1 px-2 border-accent border-2 overflow-y-auto flex flex-col">
            <Suspense fallback={null}>
              {artifact}
              {resource.status === "suspended" && isActive ? <SuspendWidget resource={resource} /> : null}
            </Suspense>
          </ResizablePanel>
        </ResizablePanelGroup>
      )}
    </div>
  )
}
