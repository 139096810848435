import type { Plugin } from 'unified';
import type { Node, Parent } from 'unist';
import { visit } from 'unist-util-visit';

interface TextNode extends Node {
  type: 'text';
  value: string;
}

interface HtmlNode extends Node {
  type: 'html';
  value: string;
}

const colorRegex = /(?:#(?:[0-9a-fA-F]{6}|[0-9a-fA-F]{3})|\brgba?\(\s*(?:\d{1,3}\s*,\s*){2}\d{1,3}(?:\s*,\s*\d*\.?\d+)?\s*\))/g;

function remarkWrapColors() {
  return (tree: Node) => {
    visit(tree, 'text', (node, index, parent) => {
      // Skip if the text is already inside an inlineCode node
      if (!parent || parent.type === 'inlineCode') return;
      
      const { value } = node;
      let match;
      const newNodes = [];
      let lastIndex = 0;
      
      // Process each match in the text node
      while ((match = colorRegex.exec(value)) !== null) {
        // Add the text before the match as a plain text node
        if (match.index > lastIndex) {
          newNodes.push({
            type: 'text',
            value: value.slice(lastIndex, match.index)
          });
        }
        // Wrap the matched color in an inline code node
        newNodes.push({
          type: 'inlineCode',
          value: match[0]
        });
        lastIndex = match.index + match[0].length;
      }
      
      // Add any remaining text after the last match
      if (newNodes.length) {
        if (lastIndex < value.length) {
          newNodes.push({
            type: 'text',
            value: value.slice(lastIndex)
          });
        }
        // Replace the original node with the new nodes
        parent.children.splice(index, 1, ...newNodes);
      }
    });
  };
}

export { remarkWrapColors };
