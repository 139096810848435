import { Button } from "@/components/ui/button"
import { Textarea } from "@/components/ui/textarea"
import { createRoute } from "@tanstack/react-router"
import { ofetch } from "ofetch"
import { XCircleIcon } from "lucide-react"
import { useState } from "react"
import { useToast } from "@/components/hooks/use-toast"
import { teamSettingsRoute } from "."

export const teamSettingsDefaultPlaygroundRoute = createRoute({
  getParentRoute: () => teamSettingsRoute,
  path: "/default-playground-settings",
  loader: async ({ params }) => {
    const response = await ofetch<Record<string, unknown>>(`/api/teams/${params.teamId}/config/default-playground-settings`)
    return response
  },
  component: function DefaultPlaygroundSettings() {
    const settings = teamSettingsDefaultPlaygroundRoute.useLoaderData()
    const { teamId } = teamSettingsRoute.useParams()
    const { toast } = useToast()
    const [jsonValue, setJsonValue] = useState<string>(JSON.stringify(settings || {}, null, 2))
    const [jsonError, setJsonError] = useState<string | null>(null)
    const [isSaving, setIsSaving] = useState(false)

    const handleJsonChange = (value: string) => {
      setJsonValue(value)
      try {
        JSON.parse(value)
        setJsonError(null)
      } catch (error) {
        setJsonError((error as Error).message)
      }
    }

    const saveSettings = async () => {
      if (jsonError) return
      
      setIsSaving(true)
      try {
        await ofetch(`/api/teams/${teamId}/config/default-playground-settings`, {
          method: "PUT",
          body: JSON.parse(jsonValue),
        })
        toast({
          title: "Success",
          description: "Default playground settings saved successfully",
        })
      } catch (error: any) {
        toast({
          variant: "destructive",
          title: "Error saving settings",
          description: error.data?.error || error.message || "Failed to save settings",
        })
      } finally {
        setIsSaving(false)
      }
    }

    return (
      <div>
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-3xl font-semibold">Default Playground Settings</h1>
            <p className="text-gray-500 mt-2">
              These settings will be applied to all playgrounds in this team unless overridden at the playground level.
            </p>
          </div>
        </div>

        <div className="space-y-4">
          <Textarea
            value={jsonValue}
            onChange={e => handleJsonChange(e.target.value)}
            className="font-mono text-sm min-h-[400px] resize-none border-2"
            placeholder="Enter your JSON settings here..."
          />

          {jsonError && (
            <div className="rounded-lg border-2 border-destructive/50 bg-destructive/50 p-4">
              <div className="flex gap-2 items-center">
                <XCircleIcon className="h-5 w-5" />
                <p className="font-medium">JSON Validation Error</p>
              </div>
              <p className="mt-2 text-sm">{jsonError}</p>
            </div>
          )}
        </div>

        <div className="flex justify-end mt-6">
          <Button
            disabled={!!jsonError || isSaving}
            onClick={saveSettings}
            className="px-8"
          >
            {isSaving ? "Saving..." : "Save Changes"}
          </Button>
        </div>
      </div>
    )
  },
}) 