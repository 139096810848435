import * as StreamFn from "../../utils/readable-streams"

export type LLMMacro = {
  name: string
  attributes: Record<string, string>
  body?: string
  toString: () => string
}

const LLMMacroToString = (macro: LLMMacro) => {
  return `[@@${macro.name} ${Object.entries(macro.attributes).map(([key, value]) => `${key}=\"${value}\"`).join(" ")} ${!macro.body ? "/]" : `]${macro.body}[/@@${macro.name}]`}`
}
class _LLMMacro {
  constructor(public name: string, public attributes: Record<string, string>, public body?: string) {
    this.toString = () => LLMMacroToString(this)
  }
}

export const parseMacroHead = (
  macro: string,
): {
  name: string
  attributes: Record<string, string>
  hasBody: boolean
  endIndex: number
} => {
  const name = macro.substring(3).match(/^[\w]+/)?.[0]
  if (!name) {
    throw new Error("Invalid macro format")
  }
  const attributes = {}
  const hasBody = macro.indexOf("/]") === -1
  const headEnd = hasBody ? macro.indexOf("]") : macro.indexOf("/]")
  const attributesMatch = macro.slice(3, headEnd).matchAll(/(\w+)=\\?['"](.+?)\\?['"]/g)
  for (const match of attributesMatch) {
    attributes[match[1]] = match[2]
  }
  return { name, attributes, hasBody, endIndex: headEnd + 1 }
}

export const parseMacro = (macro: string): LLMMacro => {
  const { name, attributes, hasBody, endIndex } = parseMacroHead(macro)
  const body = hasBody ? macro.slice(endIndex, macro.indexOf(`[/@@${name}]`)).trim() : undefined
  return new _LLMMacro(name, attributes, body)
}

export const parseMacros = (text: string, replace: (macro: LLMMacro, offset: number, length: number) => string = (x)=> x.toString()): { blocks: ({type: "text", text: string} | {type: "macro", macro: LLMMacro})[]; macros: LLMMacro[], text: string } => {
  const macrosWithoutBody = [...text.matchAll(/\[@@.*?\/]/gs)]
  const macrosWithBody = [...text.matchAll(/\[@@.*?].*?\[\/@@.*?]/gs)]
  const matches = [...macrosWithoutBody, ...macrosWithBody]
  const macros = [] as LLMMacro[]
  const blocks = [] as ({type: "text", text: string} | {type: "macro", macro: LLMMacro})[]
  let cursor = 0;
  let patchedText = text;
  for (const m of matches) {
    const textBlock = text.slice(cursor, m.index)
    if (textBlock.length > 0) {
      blocks.push({type: "text", text: textBlock})
    }
    cursor = m.index + m[0].length
    const macro = parseMacro(m[0])
    macros.push(macro)
    const macroReplacement = replace(macro, m.index, m[0].length)
    patchedText = patchedText.replace(m[0], macroReplacement)
    blocks.push({type: "macro", macro})
  }
  if (cursor < text.length) {
    blocks.push({type: "text", text: text.slice(cursor)})
  }
  return { blocks, text: patchedText, macros }
}

export const abortableStream = (abortSignal: AbortSignal) => <T>(stream: ReadableStream<T>) => {
  let reader: ReadableStreamDefaultReader<T> | null = null;
  const abortPromise = new Promise((_, reject) => {
    abortSignal.addEventListener(
      'abort',
      (x) => reject(abortSignal.reason ?? new Error('Aborted')),
      { once: true }

    );
  });
  return new ReadableStream({
    start() {
      reader = stream.getReader();
    },
    async pull(controller) {
      try {
        const { done, value } = await Promise.race([reader!.read(), abortPromise]) as { done: boolean, value: T };
        if (done) {
          controller.close();
        } else {
          controller.enqueue(value);
        }
      } catch (error) {
        controller.error(error);
      }
    },
  })
}

export const splitAiMacroStreamTokens = (stream: ReadableStream<string>) =>
  StreamFn.pipe(
    StreamFn.endsWith<string, typeof EOF>(EOF, stream),
    StreamFn.scan((current, chunk) => {
      if (chunk === EOF) {
        const last = current[current.length - 1] || ""
        const isInMacro = (last.startsWith("[@@") || last.startsWith("```[@@")) && !/]([ \t\r\f]*\n?[ \t\r\f]*\`\`\`)?/.exec(last)
        return last.replace(/\W/g, "").trim() === "" || isInMacro ? [last] : [last, ""]
      }

      if (!current.length) {
        current.push("")
      }

      let buffer = current[current.length - 1]
      current = [buffer]

      for (const c of chunk) {
        buffer += c
        const isInMacroHead = buffer.startsWith("[@@")
        const isInMacroTail = buffer.startsWith("[/@@")
        if (isInMacroHead || isInMacroTail) {
          if (buffer.match(/\][ \t\r\f]*\n?[ \t\r\f]*`{0,2}$/s)) {
            continue
          }

          const m = /\]([ \t\r\f]*\n?[ \t\r\f]*\`\`\`)?/.exec(buffer)
          if (m) {
            let index = m.index + 1
            const part = buffer.slice(0, index)
            current[current.length - 1] = part
            buffer = buffer.slice(m.index + m[0].length)
            current.push(buffer)
          }
        } else {
          const m = buffer.indexOf("[@@") !== -1 ? /(```[ \t\r\f]*\n?[ \t\r\f]*)?\[\@\@/s.exec(buffer) : /\[\/@@/s.exec(buffer)

          if (m) {
            current[current.length - 1] = buffer.slice(0, m.index)
            const cut = m[1] ? m.index + m[1].length : m.index

            buffer = buffer.slice(cut)
            current.push(buffer)
          } else {
            const space = buffer.lastIndexOf(" ")
            if (space !== -1 && buffer.replace(/\W/g, "").trim().length > 0) {
              current[current.length - 1] = buffer.slice(0, space + 1)
              buffer = buffer.slice(space + 1)
              current.push(buffer)
            }
          }
        }
      }
      current[current.length - 1] = buffer
      return current
    }, [] as string[]),
    StreamFn.map(x => x.slice(0, -1)),
    StreamFn.filter(x => x.length > 0),
    StreamFn.flatMap(x => StreamFn.from(x)),
  )

const EOF = Symbol("EOF")

export const splitAiMacroStream = (stream: ReadableStream<string>) =>
  StreamFn.pipe(
    StreamFn.endsWith<string, typeof EOF>(EOF, splitAiMacroStreamTokens(stream)),
    StreamFn.scan((current, chunk) => {
      if (current.length === 0) {
        current.push("")
      }
      current = [current[current.length - 1]]
      let buffer = current[current.length - 1]
      if (chunk === EOF) {
        return buffer === "" ? [buffer] : [buffer, ""]
      }
      if (chunk.startsWith("[@@") && buffer.length > 0) {
        current.push(chunk)
      } else {
        current[current.length - 1] += chunk
      }

      buffer = current[current.length - 1]

      if (buffer.startsWith("[@@") && (buffer.endsWith("/]") || chunk.startsWith("[/@@"))) {
        current.push("")
      }

      return current
    }, [] as string[]),
    StreamFn.map(x => x.slice(0, -1)),
    StreamFn.filter(x => x.length > 0),
    StreamFn.flatMap(x => StreamFn.from(x)),
  )
